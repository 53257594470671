header {
  background: #09032f;
  color: white;
  height: 20vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 2.5%;
  box-shadow: 0 0 32px 0 rgb(0 0 0 / 16%);
}

.header__title {
  font-size: 35px;
  font-weight: 400;
  margin-bottom: .20em;
}

.header__sub-title {
  font-size: 18px;
  font-weight: 200;
}

.real-time-interface {
  padding: 1.25% 0 0 2.5%;
}

.real-time-interface__button {
  display: flex;
  padding: .5em;
  border-radius: .5rem;
  justify-content: center;
  cursor: pointer;
  background: #6b2bd6;
  color: white;
  width: 10%;
  font-size: 18px;
}

.real-time-interface__title {
  margin-bottom: .5em;
  font-size: 18px;
  font-weight: 525;
}

.real-time-interface__message {
  text-align: left;
  width: 95%;
  margin-top: .5em;
  font-size: 19px;
  font-weight: 400;
  box-shadow: 0 0 32px 0 rgb(0 0 0 / 16%);
  padding: 1em;
  transition: .5s ease-in;
}

