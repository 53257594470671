#chat-container::-webkit-scrollbar,
#chat-container::-webkit-scrollbar-thumb {
  width: 26px;
  border-radius: 13px;
  background-clip: padding-box;
  border: 10px solid transparent;
}

#chat-container::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0 10px;
}

#chat-container {
  overflow: auto;
  height: 90%;
  align-content: end;
}

.chat-background {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  padding-bottom: 30px;
}

.chat-msg-text {
  background-color: #f1f2f6;
  padding: 15px;
  border-radius: 20px 20px 0px 20px;
  line-height: 1.5;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
  text-overflow: ellipsis;
  text-align: left;
}

.owner.chat-msg-text {
  background-color: #0086ff;
  color: #fff;
  border-radius: 20px 20px 20px 0px;
}

.system.chat-msg-text {
  background-color: #cec3c3;
  color: #000000;
  border-radius: 20px 20px 20px 20px;
  padding: 10px;
  font-size: 13px;
}

.blur-msg {
  color: transparent !important;
  text-shadow: 0 0 8px #000;
}
