.home-card-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;

}

.home-card {
    max-width: 350px!important;
    margin-bottom: 50px;
    -moz-user-select: none;  
    -webkit-user-select: none;  
    -ms-user-select: none;  
    -o-user-select: none;  
    user-select: none;
}

.card.container.home-card.home-card:hover {
    background: lightgray;
}