.recording-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0.25em;
    background-color: rgb(99, 99, 99);
    border-radius: 0.25em;    
  }
  
  .recording-circle {
    background-color: red;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    animation: ease pulse 2s infinite;
    margin-right: 0.25em;
  }
  
  .recording-text {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 0.75em;
    color: white;
  }
  
  @keyframes pulse {
    0% {
      background-color: red;
    }
    50% {
      background-color: #f06c6c;
    }
    100% {
      background-color: red;
    }
  }
  